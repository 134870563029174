.new-register {
  font-family: var(--bs-body-font-family) !important;
}
.voucher-input input::placeholder {
  opacity: 0.8 !important;
}

.errorMsg {
  color: #e00d0d !important;
  font-weight: 400;
  padding: 0 !important;
  text-transform: none;
}
.new-register .form-label span.gl-form-asterisk:after {
  content: "*";
}
.new-register .form-label span.gl-form-asterisk {
  background-color: inherit;
  color: #e32b2b;
  padding: 0 0 0 3px;
}

.new-register .h1,
.h2,
.new-register .h3,
.new-register .h4,
.new-register .h5,
.new-register .h6,
.new-register h1,
.new-register h2,
.new-register h3,
.new-register h4,
.new-register h5,
.new-register h6 {
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: 500;
  line-height: 1.2;
}

.new-register .py-8 {
  padding-bottom: 72px !important;
  padding-top: 72px !important;
}
.new-register .p-3 {
  padding: 16px !important;
}
.new-register .rounded-4 {
  border-radius: 16px !important;
}
.new-register .gy-4 {
  --bs-gutter-y: 24px !important;
  --bs-gutter-x: 24px !important;
}
.new-register .gy-3 {
  --bs-gutter-y: 16px !important;
  --bs-gutter-x: 24px !important;
}
.new-register .alert-info {
  margin: 0 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 16px !important;
}
.new-register .register-logo {
  background: hsla(0, 0%, 100%, 0.851);

  padding: 35px 0;
}
.new-register .register-logo img {
  width: 30%;
  margin: 0 auto;
  display: block;
}

@media (min-width: 576px) {
  .new-register .py-sm-8 {
    padding-bottom: 72px !important;
    padding-top: 72px !important;
  }
}
@media (min-width: 768px) {
  .new-register .py-md-8 {
    padding-bottom: 72px !important;
    padding-top: 72px !important;
  }
  .new-register .p-md-4 {
    padding: 24px !important;
  }
}
@media (min-width: 992px) {
  .new-register .py-lg-8 {
    padding-bottom: 72px !important;
    padding-top: 72px !important;
  }
}
@media (min-width: 1200px) {
  .new-register .py-xl-8 {
    padding-bottom: 72px !important;
    padding-top: 72px !important;
  }
  .new-register .p-xl-5 {
    padding: 48px !important;
  }
}
@media (min-width: 1400px) {
  .new-register .py-xxl-8 {
    padding-bottom: 72px !important;
    padding-top: 72px !important;
  }
}
.new-register .bsb-btn-xl {
  --bs-btn-padding-y: 10px;
  --bs-btn-padding-x: 20px;
  --bs-btn-font-size: calc(20.16px + 0.12vw);
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .new-register .bsb-btn-xl {
    --bs-btn-font-size: 21.6px;
  }
}
.new-register .bsb-btn-2xl {
  --bs-btn-padding-y: 12px;
  --bs-btn-padding-x: 24px;
  --bs-btn-font-size: calc(20.32px + 0.24vw);
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .new-register .bsb-btn-2xl {
    --bs-btn-font-size: 23.2px;
  }
}
.new-register .bsb-btn-3xl {
  --bs-btn-padding-y: 14px;
  --bs-btn-padding-x: 28px;
  --bs-btn-font-size: calc(20.48px + 0.36vw);
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .new-register .bsb-btn-3xl {
    --bs-btn-font-size: 24.8px;
  }
}
.new-register .bsb-btn-4xl {
  --bs-btn-padding-y: 16px;
  --bs-btn-padding-x: 32px;
  --bs-btn-font-size: calc(20.64px + 0.48vw);
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .new-register .bsb-btn-4xl {
    --bs-btn-font-size: 26.4px;
  }
}
.new-register .bsb-btn-5xl {
  --bs-btn-padding-y: 18px;
  --bs-btn-padding-x: 36px;
  --bs-btn-font-size: calc(20.8px + 0.6vw);
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .new-register .bsb-btn-5xl {
    --bs-btn-font-size: 28px;
  }
}
.new-register .bsb-btn-circle {
  align-items: center;
  backface-visibility: hidden;
  border-radius: 50% !important;
  display: inline-flex;
  height: 40px;
  justify-content: center;
  line-height: 1;
  padding: 0;
  width: 40px;
}
.new-register .bsb-btn-circle.bsb-btn-circle-sm {
  height: 32px;
  width: 32px;
}
.new-register .bsb-btn-circle.bsb-btn-circle-lg {
  height: 48px;
  width: 48px;
}
.new-register .bsb-btn-circle.bsb-btn-circle-xl {
  height: 56px;
  width: 56px;
}
.new-register .bsb-btn-circle.bsb-btn-circle-2xl {
  height: 64px;
  width: 64px;
}
.new-register .bsb-btn-circle.bsb-btn-circle-3xl {
  height: 72px;
  width: 72px;
}
.new-register .bsb-btn-circle.bsb-btn-circle-4xl {
  height: 80px;
  width: 80px;
}
.new-register .bsb-btn-circle.bsb-btn-circle-5xl {
  height: 88px;
  width: 88px;
}
.new-register .bsb-btn-circle.bsb-btn-circle-6xl {
  height: 96px;
  width: 96px;
}
.new-register .bsb-btn-circle.bsb-btn-circle-7xl {
  height: 104px;
  width: 104px;
}
.new-register .bsb-btn-circle.bsb-btn-circle-8xl {
  height: 112px;
  width: 112px;
}
.new-register .bsb-btn-circle.bsb-btn-circle-9xl {
  height: 120px;
  width: 120px;
}
.new-register .bsb-btn-circle.bsb-btn-circle-10xl {
  height: 128px;
  width: 128px;
}
.new-register .form-floating > label {
  position: absolute;
  top: -14px;
  left: 12px;
  z-index: 2;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  background: #fff;
  padding: 0;
  height: auto;

  font-size: 16px;
  font-weight: 400;
  line-height: var(--bs-body-line-height);
}
.new-register .icon-position {
  position: absolute;
  font-size: 18px !important;
  padding: 17px 12px;
  color: var(--secondary-color);
  z-index: 11;
}
.new-register .form-floating > .form-control,
.new-register .form-floating > .form-control-plaintext {
  padding: 8px 12px;
  padding-left: 28.8px;
}
.new-register .form-floating > .form-control-plaintext:focus,
.new-register .form-floating > .form-control-plaintext:not(:placeholder-shown),
.new-register .form-floating > .form-control:focus,
.new-register .form-floating > .form-control:not(:placeholder-shown) {
  padding: 16px 12px;
  padding-left: 28.8px;
}
.new-register label.form-label.form-label-select {
  color: #000 !important;
  font-size: 18px;
  top: -6px;
  max-width: 100%;
  /* right: 0; */
}
.new-register .btn-primary {
  padding: 9px;
  border-radius: 8px;
  font-size: 20px;
}
.new-register .top-bg {
  border-radius: 5px;
  position: relative;
  padding: 10px;
}
.new-register .top-bg:after {
  position: absolute;
  z-index: 5;
  height: 100%;
  width: 100%;
  content: "";
  background: var(--primary-color);
  top: 0;
  left: 0;
  border-radius: 5px;
}
.new-register .top-bg h2.h1.mb-4 {
  z-index: 15;
  position: relative;
}
.new-register .form-floating > .form-control,
.new-register .form-floating > .form-control-plaintext,
.new-register .form-floating > .form-select,
.new-register .form-floating span.select2-selection.select2-selection--single {
  height: calc(48px + calc(var(--bs-border-width) * 2));
  min-height: calc(48px + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.new-register
  .form-floating
  .select2-container--default
  .select2-selection--single {
  border-radius: 4px;
  border: var(--bs-border-width) solid var(--bs-border-color);
}

.new-register
  .form-floating
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  padding: 11px 12px;
  padding-left: 28.8px;
}
.new-register
  .form-floating
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  padding: 24px 17px;
}
.new-register
  .form-floating
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-width: 6px 5px 0 5px;
}

.new-register .form-heading {
  font-size: 32px;
  z-index: 10;
  position: relative;
  color: white;
}
.new-register.form-body-center {
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 15px 0px;
}
.new-register .alert-info-custom {
  margin-bottom: 0;
}
.new-register.form-section-bg {
  background: url(../Images/bg-01.webp) no-repeat;
  background-position: top center;
  background-size: cover;
  position: relative;
}
.new-register.form-section-bg:after {
  background: rgb(47 47 47 / 62%);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  content: "";
}
.new-register.form-section-bg .container {
  z-index: 10;
}

.new-register .form-check .form-check-label button.btn.btn-primary {
  padding: 0;
  border-radius: 0;
  font-size: 14px;
  background: transparent;
  color: var(--secondary-color);
  border: 0;
  vertical-align: text-top;
  font-weight: 700;
}

@media only screen and (max-width: 992px) {
  .new-register .container {
    max-width: 100%;
  }
  .new-register .overflow-hider {
    overflow: auto;
    width: 100%;
    height: 85px;
  }
}
@media only screen and (max-width: 768px) {
  .new-register .card-body.p-3.p-md-4.p-xl-5 {
    padding-top: 30px !important;
  }
  .new-register .form-heading {
    font-size: 22px;
    text-align: center;
  }
  .new-register.form-body-center {
    padding: 15px 0;
  }
  .new-register .register-logo {
    padding: 15px 0;
  }
  .new-register .register-logo img {
    width: 60%;
  }
  .new-register .overflow-hider {
    overflow: auto;
    width: 100%;
    height: 85px;
  }
}
.new-register .form-floating > .form-control-plaintext ~ label,
.new-register .form-floating > .form-control:focus ~ label,
.new-register .form-floating > .form-control:not(:placeholder-shown) ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-8px) translateX(2.4px);
}

.new-register .select_1.dropdown.form-control.css-b62m3t-container {
  padding: 0 !important;
  border: 0;
}

.new-register .css-t3ipsp-control,
.new-register .css-13cymwt-control {
  height: calc(48px + calc(var(--bs-border-width) * 2));
  min-height: calc(48px + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
  padding-left: 18px;
  padding-top: 3px;
}

.new-register .error-select-search .css-13cymwt-control,
.new-register .error-select-search .css-t3ipsp-control {
  border: 1px solid red !important;
}
.new-register .card-body.p-3.p-md-4.p-xl-5.thank svg {
  width: 120px;
  height: 120px;
  font-size: 52px;
  border: 2px solid #04b954;
  border-radius: 50%;
  padding: 30px;

  color: #04b954;

  display: block;
  float: left;
}
.new-register .thank-text {
  width: calc(100% - 120px);
  padding-left: 20px;
  float: right;
}

.new-register .thank-para {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 10px;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-color: var(--bs-border-color) !important;
  border-width: 1px !important;
  border-style: solid !important;
}
.checkbox-group > * {
  margin: 8px 8px;
}

.checkbox-group-legend {
  margin: 0;
  font-weight: 400;
  color: #000000;
  font-size: 18px;
  text-align: left;
  line-height: 1.125;
  margin-bottom: 2px;
}

.checkbox-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.checkbox-input:checked + .checkbox-tile {
  border-color: var(--secondary-color);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: var(--secondary-color);
}
.checkbox-input:checked + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}
.checkbox-input:checked + .checkbox-tile .checkbox-icon,
.checkbox-input:checked + .checkbox-tile .checkbox-label {
  color: var(--secondary-color);
}
/* .checkbox-input:focus + .checkbox-tile {
  border-color: var(--secondary-color);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px var(--secondary-color);
} */
.checkbox-input:focus + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
}
.checkbox-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  min-height: 154px;
  border-radius: 0;
  border: 2px solid #fff;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
}
.checkbox-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  border-radius: 50%;
  top: 4px;
  left: 4px;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.checkbox-tile:hover {
  border-color: var(--secondary-color);
}
.checkbox-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}

.checkbox-icon {
  transition: 0.375s ease;
  color: #494949;
}
.checkbox-icon img {
  width: 150px;
  object-fit: contain;
  height: 100px;
  margin-bottom: 5px;
}

.checkbox-label {
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
}
.checkbox-icon .pirds {
  width: 150px;
  height: 100px;
  border-radius: 0;
  margin-bottom: 5px;
}
.checkbox-icon .rdcrdtop table tr td {
  height: 65px;
}

.select2-search__field:focus-visible {
  outline: none !important;
  border-color: var(--secondary-color) !important;
}

.col-12.col-xl-12.mt-0.text-center h2.form-heading {
  color: var(--secondary-color);
}
